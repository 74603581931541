












import { ApiApplicationFormTemplateAdditionalQuestionDto } from "@/api/generated/Api";
import { QuestionType, QuestionValueType } from "@/shared/types/questions.types";
import { computed, defineComponent, onMounted, PropType, ref } from "@vue/composition-api";

export default defineComponent({
  name: "ConditionalAdditionalQuestion",
  props: {
    questionData: {
      type: Array as PropType<ApiApplicationFormTemplateAdditionalQuestionDto[]>,
      required: true,
    },
    question: {
      type: Object as PropType<QuestionType>,
      required: true,
    },
    questionNumber: {
      type: Number,
      required: true,
    },
    readonly: {
      type: Boolean,
      required: true,
    },
  },
  emits: ["update"],
  setup(props, { emit }) {
    const displayIfQuestionIdIsTrue = ref<string | unknown>("");
    const questionReferences = computed(() =>
      props.questionData.filter((question) => question.type === QuestionValueType.Boolean)
    );
    const questionReferenceOptions = computed(() => [
      {
        text: "Alltid vis dette spørsmålet",
        value: "",
      },
      ...questionReferences.value.map(({ id, description, questionNumber }) => ({
        text: `Vis kun hvis bruker svarer "ja" på spørsmål ${questionNumber}${description && ` ("${description}")`}`,
        value: id,
      })),
    ]);

    const emitUpdate = () => {
      emit("update", props.question.id, {
        displayIfQuestionIdIsTrue: displayIfQuestionIdIsTrue.value || undefined,
      });
    };

    onMounted(() => {
      displayIfQuestionIdIsTrue.value = props.question.displayIfQuestionIdIsTrue || "";
    });

    return {
      displayIfQuestionIdIsTrue,
      questionReferences,
      questionReferenceOptions,
      emitUpdate,
    };
  },
});
